import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./pages/Layout";

import UserAgreement from "./pages/UserAgreement";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/" element={<Layout />}>
        <Route path="user-agreement" element={<UserAgreement />} />
      </Route>
      <Route path="*" element={<Home />} />
    </Routes>
  );
}
